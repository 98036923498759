<template>
  <div class="user-verification-wrapper">
    <div class="header">
      <img
        src="@/assets/images/logo/norskhelse_logo.svg"
        alt="logo"
      >
    </div>

    <div class="body">
      <b-row class="auth-inner m-0">

        <b-col
          lg="5"
          class="align-items-center p-md-2 pt-lg-4 pt-xl-5"
        >
          <div class="welcome-text px-5">
            {{ $t('Welcome to the Norwegian Health Portal') }}
          </div>
          <h3
            v-if="userName"
            class="font-weight-bold px-5 pb-md-2"
          >
            {{ userName }}
          </h3>
          <div class="p-lg-4">
            <b-img
              fluid-grow
              :src="welcomeImg"
              :alt="$t('Welcome cats')"
            />
          </div>
        </b-col>

        <b-col
          class="auth-bg px-2 pt-md-3 d-flex align-items-center justify-content-center"
        >

          <validation-observer
            ref="verificationValidation"
            class="w-100"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >

              <b-row class="d-flex justify-content-center">

                <b-col
                  md="6"
                  xl="5"
                >

                  <b-form-group
                    :label="$t('Email')"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        :state="errors.length > 0 ? false:null"
                        name="email"
                        :placeholder="$t('New email')"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col
                  md="6"
                  xl="5"
                >

                  <b-form-group
                    :label="$t('Password')"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        :state="errors.length > 0 ? false:null"
                        name="password"
                        type="password"
                        :placeholder="$t('Password')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

              </b-row>

              <b-form-group
                class="d-flex justify-content-center pt-md-2"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ $t('Verify new email') }}
                </b-button>
              </b-form-group>

            </b-form>
          </validation-observer>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-modal-term-privacy-policy"
      :title="$t('Overflowing Content')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Close')"
      :ok-title="$t('Accept')"
    >
      <b-card-text>
        Some text
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCardText,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
  email,
  min,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ConfirmChangeableEmail',
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      welcomeImg: require('@/assets/images/pages/undraw_welcome_cats_thqn_1.svg'),
      userName: null,
      form: {
        email: '',
        password: '',
      },
      emailToken: null,
      required,
      email,
      min,
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.newEmail && this.$route.query.token) {
      this.form.email = this.$route.query.newEmail
      this.emailToken = this.$route.query.token
    }
    const user = this.$store.getters['auth/user']
    if (user && user.user_info && user.user_info.first_name && user.user_info.last_name) {
      this.userName = `${user.user_info.first_name} ${user.user_info.last_name}`
    }
  },
  methods: {
    validationForm() {
      this.$refs.verificationValidation.validate().then(success => {
        if (success) {
          const userData = {
            new_email: this.form.email,
            password: this.form.password,
            token: this.emailToken,
          }
          this.$store.dispatch('auth/confirmUserEmail', userData)
            .then(() => {
              this.$router.push({ name: 'home' })

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('E-mail has been successfully confirmed.'),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
            })
            .catch(e => {
              if (e.response.status === 408) {
                this.$router.push({ name: 'home' }).then(() => { EventBus.$emit('showSignUpModal') })
              } else if (e.response.status === 422) {
                this.$store.dispatch('formErrorResponseResolver', e).then(errors => {
                  this.$refs.verificationValidation.setErrors(errors)
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
